import dayjs from 'dayjs';

const address = (path: string) => ({
  streetName: {
    name: 'Straatnaam',
    value: `{{${path}.streetName}}`,
    sample: 'Postbus',
  },
  houseNumberIncludingAddition: {
    name: 'Huisnummer',
    value: `{{${path}.houseNumberIncludingAddition}}`,
    sample: '987',
  },
  zipCode: {
    name: 'Postcode',
    value: `{{${path}.zipCode}}`,
    sample: '5600 AZ',
  },
  city: {
    name: 'Stad',
    value: `{{${path}.city}}`,
    sample: 'Eindhoven',
  },
  country: {
    name: 'Land',
    value: `{{${path}.country}}`,
    sample: 'Nederland',
  },
});

const identity = {
  name: 'Identiteit',
  mergeTags: {
    logoUrl: {
      name: 'Logo',
      value: '{{identity.logoUrl}}',
      sample: 'https://site.bas.software/wp-content/uploads/2020/09/logo.png',
    },
    companyName: {
      name: 'Bedrijfsnaam',
      value: '{{identity.companyName}}',
      sample: 'Bas Software B.V.',
    },
    phoneNumber: {
      name: 'Telefoonnummer',
      value: '{{identity.phoneNumber}}',
      sample: '085-3033415',
    },
    mobileNumber: {
      name: 'Mobielenummer',
      value: '{{identity.mobileNumber}}',
      sample: '085-3033415',
    },
    emailAddress: {
      name: 'Email',
      value: '{{identity.emailAddress}}',
      sample: 'info@bas.software',
    },
    cocNumber: {
      name: 'KVK nummer',
      value: '{{identity.cocNumber}}',
      sample: '530560318',
    },
    vatNumber: {
      name: 'BTW nummer',
      value: '{{identity.vatNumber}}',
      sample: 'NL087370979B15',
    },
    ibanNumber: {
      name: 'IBAN',
      value: '{{identity.ibanNumber}}',
      sample: 'NL72INGB0956389430',
    },
    address: {
      name: 'Adres',
      mergeTags: address('identity.address'),
    },
  },
};

const contactPerson = (contactType: string) => ({
  salutation: {
    name: 'Aanhef',
    value: `{{customer.${contactType}.salutation}}`,
    sample: 'heer',
  },
  firstName: {
    name: 'Voornaam',
    value: `{{customer.${contactType}.firstName}}`,
    sample: 'Bas',
  },
  lastName: {
    name: 'Achternaam',
    value: `{{customer.${contactType}.lastName}}`,
    sample: 'de Jong',
  },
  phoneNumber: {
    name: 'Telefoonnummer',
    value: `{{customer.${contactType}.phoneNumber}}`,
  },
  mobileNumber: {
    name: 'Mobielenummer',
    value: `{{customer.${contactType}.mobileNumber}}`,
  },
  emailAddress: {
    name: 'Email',
    value: `{{customer.${contactType}.emailAddress}}`,
  },
});

const customer = {
  name: 'Klant',
  mergeTags: {
    salutation: {
      name: 'Aanhef',
      value: `{{customer.salutation}}`,
      sample: 'heer',
    },
    name: {
      name: 'Naam',
      value: '{{customer.name}}',
      sample: 'Bas de Jong',
    },
    firstName: {
      name: 'Voornaam',
      value: '{{customer.firstName}}',
      sample: 'Bas',
    },
    lastName: {
      name: 'Achternaam',
      value: '{{customer.lastName}}',
      sample: 'de Jong',
    },
    companyName: {
      name: 'Bedrijfsnaam',
      value: '{{customer.companyName}}',
      sample: 'Bas Software B.V.',
    },
    vatNumber: {
      name: 'BTW nummer',
      value: '{{customer.vatNumber}}',
      sample: 'NL676770265B70',
    },
    phoneNumber: {
      name: 'Telefoonnummer',
      value: '{{customer.phoneNumber}}',
    },
    mobileNumber: {
      name: 'Mobielenummer',
      value: '{{customer.mobileNumber}}',
    },
    emailAddress: {
      name: 'Email',
      value: '{{customer.emailAddress}}',
    },
    invoiceAddress: {
      name: 'Factuur adres',
      mergeTags: address('customer.invoiceAddress'),
    },
    primaryContactPerson: {
      name: 'Hoofd contactpersoon',
      mergeTags: contactPerson('primaryContactPerson'),
    },
    invoiceContactPerson: {
      name: 'Facturatie contactpersoon',
      mergeTags: contactPerson('invoiceContactPerson'),
    },
  },
};

const employee = {
  name: 'Medewerker',
  mergeTags: {
    firstName: {
      name: 'Voornaam',
      value: '{{employee.firstName}}',
      sample: 'Bas',
    },
    lastName: {
      name: 'Achternaam',
      value: '{{employee.lastName}}',
      sample: 'de Medewerker',
    },
    phoneNumber: {
      name: 'Telefoonnummer',
      value: '{{employee.phoneNumber}}',
    },
    mobileNumber: {
      name: 'Mobielenummer',
      value: '{{employee.mobileNumber}}',
    },
    emailAddress: {
      name: 'Email',
      value: '{{employee.emailAddress}}',
    },
  },
};

const invoice = {
  name: 'Factuur',
  mergeTags: {
    invoiceNumber: {
      name: 'Factuurnummer',
      value: '{{invoice.invoiceNumber}}',
      sample: '2021-00001',
    },
    invoicedAt: {
      name: 'Factuurdatum',
      value: '{{invoice.invoicedAt}}',
      sample: dayjs().format('DD-MM-YYYY'),
    },
    expiresAt: {
      name: 'Vervaldatum',
      value: '{{invoice.expiresAt}}',
      sample: dayjs().format('DD-MM-YYYY'),
    },
    totalIncludingVat: {
      name: 'Totaal incl btw.',
      value: '{{invoice.totalIncludingVat}}',
      sample: '€ 100,50',
    },
    openAmount: {
      name: 'Openstaand bedrag',
      value: '{{invoice.openAmount}}',
      sample: '€ 100,50',
    },
  },
};

const quote = {
  name: 'Offerte',
  mergeTags: {
    invoiceNumber: {
      name: 'Offertenummer',
      value: '{{quote.quotationNumber}}',
      sample: '2021-00001',
    },
    expiresAt: {
      name: 'Vervaldatum',
      value: '{{quote.expiresAt}}',
      sample: dayjs().format('DD-MM-YYYY'),
    },
    totalIncludingVat: {
      name: 'Totaal incl btw.',
      value: '{{quote.totalIncludingVat}}',
      sample: '€ 100,50',
    },
  },
};

const event = {
  name: 'Afspraak',
  mergeTags: {
    date: {
      name: 'Datum',
      value: '{{event.date}}',
      sample: dayjs().format('DD-MM-YYYY'),
    },
    start: {
      name: 'Start tijd',
      value: '{{event.start}}',
      sample: dayjs().format('HH:mm'),
    },
    end: {
      name: 'Eind tijd',
      value: '{{event.end}}',
      sample: dayjs().format('HH:mm'),
    },
  },
};

const events = {
  name: 'Afspraken',
  rules: {
    repeat: {
      name: 'Voor elke afspraak',
      before: '{% for event in events %}',
      after: '{% endfor %}',
    },
  },
  mergeTags: {
    ...event.mergeTags,
    eventType: {
      name: 'Type afspraak',
      value: '{{event.eventType}}',
      sample: 'Verhuizing',
    },
  },
};

const movingEvens = {
  name: 'Verhuis afspraken',
  rules: {
    repeat: {
      name: 'Voor elke verhuis afspraak',
      before:
        "{% for event in events|filter(event => event.realEventType in ['moving-job-moving-lift', 'moving-job-moving', 'moving-job-load-storage', 'moving-job-unload-storage']) %}",
      after: '{% endfor %}',
    },
  },
  mergeTags: {
    ...event.mergeTags,
    eventType: {
      name: 'Type afspraak',
      value: '{{event.eventType}}',
      sample: 'Verhuizing',
    },
  },
};

const nonMovingEvens = {
  name: 'Overige afspraken',
  rules: {
    repeat: {
      name: 'Voor elke overige afspraak',
      before:
        "{% for event in events|filter(event => event.realEventType not in ['moving-job-moving-lift', 'moving-job-moving', 'moving-job-load-storage', 'moving-job-unload-storage']) %}",
      after: '{% endfor %}',
    },
  },
  mergeTags: {
    ...event.mergeTags,
    eventType: {
      name: 'Type afspraak',
      value: '{{event.eventType}}',
      sample: 'Dozen leveren',
    },
  },
};

const user = {
  name: 'Gebruiker',
  mergeTags: {
    firstName: {
      name: 'Voornaam',
      value: '{{user.firstName}}',
      sample: 'Bas',
    },
    lastName: {
      name: 'Achternaam',
      value: '{{user.lastName}}',
      sample: 'de Jong',
    },
    emailAddress: {
      name: 'Email',
      value: '{{user.emailAddress}}',
    },
  },
};

export const MergeTagsByAutomatedEmailType: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
} = {
  invoice: {
    identity,
    customer,
    invoice,
  },
  reminder_invoice: {
    identity,
    customer,
    invoice,
  },
  second_reminder_invoice: {
    identity,
    customer,
    invoice,
  },
  last_reminder_invoice: {
    identity,
    customer,
    invoice,
  },
  received_payment: {
    identity,
    customer,
    invoice,
  },
  quote: {
    identity,
    customer,
    quote,
  },
  reminder_quote: {
    identity,
    customer,
    quote,
  },
  quote_was_accepted_by_customer: {
    identity,
    customer,
    quote,
  },
  intake_appointment_confirmation: {
    identity,
    customer,
    event,
    employee,
  },
  pickup_boxes_appointment_confirmation: {
    identity,
    customer,
    event,
    employee,
  },
  planned_moving_appointment_confirmation: {
    identity,
    customer,
    events,
    movingEvens,
    nonMovingEvens,
  },
  updated_appointment_confirmation: {
    identity,
    customer,
    events,
    movingEvens,
    nonMovingEvens,
  },
  moving_date_is_approaching: {
    identity,
    customer,
    events,
    movingEvens,
    nonMovingEvens,
  },
  new_employee: {
    employee,
  },
  two_factor_auth_code: {
    personName: {
      name: 'Naam',
      mergeTags: {
        firstName: {
          name: 'Voornaam',
          value: '{{firstName}}',
          sample: 'Bas',
        },
        lastName: {
          name: 'Achternaam',
          value: '{{lastName}}',
          sample: 'de Jong',
        },
      },
    },
    authCode: {
      name: 'Authenticatie code',
      value: '{{authCode}}',
    },
  },
  resource_request_invitation: {
    personName: {
      name: 'Naam',
      mergeTags: {
        firstName: {
          name: 'Voornaam',
          value: '{{personName.firstName}}',
          sample: 'Bas',
        },
        lastName: {
          name: 'Achternaam',
          value: '{{personName.lastName}}',
          sample: 'de Jong',
        },
      },
    },
    date: {
      name: 'Werkzaamheden datum',
      value: '{{date}}',
      sample: dayjs().format('DD-MM-YYYY'),
    },
    startTime: {
      name: 'Start tijd',
      value: '{{startTime}}',
      sample: dayjs().format('HH:mm'),
    },
    endTime: {
      name: 'Eind tijd',
      value: '{{endTime}}',
      sample: dayjs().format('HH:mm'),
    },
  },
  password_reset_request: {
    user,
    date: {
      name: 'Aanvraag datum',
      value: '{{date}}',
      sample: dayjs().format('DD-MM-YYYY'),
    },
    start: {
      name: 'Aanvraag tijd',
      value: '{{time}}',
      sample: dayjs().format('HH:mm'),
    },
  },
};
