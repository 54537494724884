import { isObjectResponse } from '@bas/value-objects';
import type { PlannedTemporaryEmployee } from '../types';

export function isPlannedTemporaryEmployee(
  object: unknown
): object is PlannedTemporaryEmployee {
  return (
    !!object &&
    typeof object === 'object' &&
    isObjectResponse(object) &&
    object['@type'] === 'PlannedTemporaryEmployee'
  );
}
